















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action } from "vuex-class";
import { AppState, User, Organization } from "@/types/Types";
import * as backend from "@/api/backend";

@Component
export default class HelloWorld extends Vue {
  @Getter("getOrganizations", { namespace: "user" })
  private getOrganizations: () => Organization[];
  @Getter("getUserOrganization", { namespace: "user" })
  private getUserOrganization: (id: string) => Organization;

  @Getter("getOrganization", { namespace: "organization" })
  private getOrganization: () => Organization;
  @Action("setCurrentOrganization", { namespace: "organization" })
  private setCurrentOrganization: (org: Organization) => void;

  private setOrganization(value: string): void {
    let currentOrg = this.getUserOrganization(value);

    this.setCurrentOrganization(currentOrg);

    alert("Beállított szervezet: " + this.getOrganization().name);
  }
}
