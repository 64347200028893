

































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { State, Getter, Action } from 'vuex-class'
import { AppState, User, Organization } from '@/types/Types'
import * as backend from '@/api/backend'

@Component({
  name: 'SideMenu'
})
export default class SideMenu extends Vue {
  @State('appState') appState: AppState
  @Getter('hasRole', { namespace: 'tokenStore' }) private hasRole: (model: string) => boolean
  @Getter('getUser', { namespace: 'user' }) private getUser: () => User
  @Getter('hasAnyOfRoles', { namespace: 'tokenStore' }) private hasAnyOfRoles: (roles: string[]) => boolean;

  @Action('setModel', { namespace: 'appState' }) private setModel: (model: boolean) => void

  @Getter('getOrganization', { namespace: 'organization' }) private getOrganization: () => Organization;

  get userId (): string | null{
    return this.getUser().id
  }

  private drawerChanged (value: boolean): void {
    this.setModel(value)
  }

}

