






















import HelloWorld from "./components/HelloWorld.vue";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";
import { AppState, User, Organization } from "@/types/Types";
import api from "@/api";
import { EventBus } from "@/event-bus.ts";
import { i18n } from "@/plugins/i18n.ts";
import * as backend from "@/api/backend";
import AppNavigation from "@/components/AppNavigation.vue";
import pkceChallenge from "pkce-challenge";
//import getPkce from "oauth-pkce";
import { TokenState, Token, TokenValue, Pkce } from "@/types/TokenTypes";

@Component({
  components: {
    AppNavigation
  }
})
export default class App extends Vue {
  @State("appState")
  appState: AppState;

  @Action("retrieveToken", { namespace: "tokenStore" })
  private retrieveToken: (token: Object) => Promise<any>;

  @Action("storePkce", { namespace: "tokenStore" })
  private storePkce: (pkce: Pkce) => void;

  // @Action("refreshToken", { namespace: "tokenStore" })
  // private refreshToken: () => void;

  @Action("logout", { namespace: "tokenStore" })
  private logout: () => void;

  @Action("redirectToLogin", { namespace: "tokenStore" })
  private redirectToLogin: () => void;

  @Getter("getAccessToken", { namespace: "tokenStore" })
  private getAccessToken: () => boolean;

  @Getter("isAccessTokenExpired", { namespace: "tokenStore" })
  private isAccessTokenExpired: () => boolean;

  @Getter("getRefreshToken", { namespace: "tokenStore" })
  private getRefreshToken: () => string;

  @Getter("getPkce", { namespace: "tokenStore" })
  private getPkceCh: () => Pkce | null;

  @Getter("getRoles", { namespace: "tokenStore" })
  private getRoles: () => string[];

  private snackbar = false;

  private snackbarMsg = "";

  private isErrorMsg = false;

  get loggedIn(): boolean {
    return this.getAccessToken() != null;
  }

  private created(): void {
    console.log("App.vue created()");

    if (this.getPkceCh() == null) {
      const p: any = pkceChallenge()
          const pkce: Pkce = new Pkce(p.code_verifier, p.code_challenge);
          // alert("GENERATED PKCE: " + pkce.verifier + "  " + pkce.challenge);
          this.storePkce(pkce);
      // getPkce(32, (error, { verifier, challenge }) => {
      //   if (!error) {
      //     console.log({ verifier, challenge });
      //   }
      // });

    }

    let i = window.location.href.indexOf("code");
    let code = window.location.href.substring(i + 5);

    if (!this.loggedIn && i != -1) {
      // alert("code: " + code);
      this.retrieveToken(code).then(function() {
        console.log("Go to find and create...");
        backend.findOrCreateUser();
      });
      this.$router.push("/");
    } else if (!this.loggedIn) {
      this.login();
    }

    EventBus.$on("errorEvent", (v: string): void => {
      this.isErrorMsg = true;
      this.snackbar = true;
      this.snackbarMsg = v;
    });

    EventBus.$on("successEvent", (v: string): void => {
      console.log("App.vue successEvent");
      this.isErrorMsg = false;
      this.snackbar = true;
      this.snackbarMsg = v;
    });

    EventBus.$on("deleteEvent", (v: string): void => {
      this.isErrorMsg = false;
      this.snackbar = true;
      this.snackbarMsg = v;
    });

    // this.$vuetify.theme.dark = this.appState.theme.dark;
    // this.$vuetify.lang.current = this.appState.lang;
    // i18n.locale = this.appState.lang;
    // this.$di.load();

    // if (this.getOrganization() == null) {
    //   this.$router.push("/");
    // }
  }

  private login(): void {
    this.redirectToLogin();
  }

  private testPublic(): void {
    let params = { id: "123", name: "zergehere" };

    api
      .post(process.env.VUE_APP_API_URL + "/api/public/save", params)
      .then(response => {
        console.log(
          "Teszt válaszolt: " + JSON.stringify(response.data, null, 2)
        );
      })
      .catch(error => console.log("error: " + error));
  }

  private testPrivate(): void {
    let params = { id: "123", name: "zergehere" };

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken()
      }
    };

    api
      .post(process.env.VUE_APP_API_URL + "/api/private/save", params, config)
      .then(response => {
        console.log(
          "Teszt válaszolt: " + JSON.stringify(response.data, null, 2)
        );
      })
      .catch(error => console.log("error: " + error));
  }

  private logOut(): void {
    this.logout();
  }
}
