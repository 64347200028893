export class Pkce{
	verifier: string;
	challenge: string;
	constructor(verifier: string, challenge: string){
		this.verifier = verifier
		this.challenge = challenge
	}
}
export class Token {
    access_token: string | null;
    refresh_token: string | null;
	expires_in: number;
	expires_date: Date | null;
    refresh_expires_in: number;
    token_type: string;
    scope: string;
	access_token_decoded: TokenValue | null;
	refresh_token_decoded: TokenValue | null;
	session_state: string | null;
	pkce: Pkce | null;
    constructor(){
        this.access_token = null
        this.refresh_token = null
        this.expires_in = 0
        this.refresh_expires_in = 0
        this.token_type = ''
        this.scope = ''
		this.access_token_decoded = null
		this.refresh_token_decoded = null
		this.session_state = null
		this.expires_date = null
		this.pkce = null
    }
}

export class TokenValue{
    
	"jti": string;
	"exp": number;
	"nbf": number;
	"iat": number;
	"iss": string;
	"aud": string;
	"sub": string;
	"typ": string;
	"azp": string;
	"auth_time": number;
	"session_state": string;
	"acr": string;
	"allowed-origins": [];
	"realm_access": {
		"roles": []
	};
	"resource_access": {
		"glider": {
			"roles": string[]
		};
		"account": {
			"roles": string[]
		}
	};
	"scope": string;
	"email_verified": boolean;
	"name": string;
	"preferred_username": string;
	"given_name": string;
	"locale": string;
	"family_name": string;
	"email": string;

}

export interface TokenState {
    token: Token;
}
