import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/lang/en.json'
import hu from '@/lang/hu.json'

Vue.use(VueI18n)

export const DEFAULT_LANGUAGE = 'hu'
export const FALLBACK_LANGUAGE = 'en'
export const SUPPORTED_LANGUAGES = ['en', 'hu']

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { en, hu }
}
)
