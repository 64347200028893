





















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { State, Getter, Action, Mutation } from 'vuex-class'
import { AppState, User, Organization } from '@/types/Types'
import SideMenu from '@/components/SideMenu.vue'
import { i18n } from '@/plugins/i18n.ts'

@Component({
  name: 'AppNavigation',
  components: {
    SideMenu
  }
})

export default class AppNavigation extends Vue {
  @Action('setModel', { namespace: 'appState' }) private setModel: (model: boolean) => void
  @State('appState') appState: AppState;
  @Getter("getOrganization", { namespace: "organization" })
  private getOrganization: () => Organization;

  get org(): Organization {
    return this.getOrganization();
  }

  private hasRole: boolean

  private snackbar = false

  private isErrorMsg = false

  private created (): void {
    console.log('AppNavigation created()')

    this.$vuetify.theme.dark = this.appState.theme.dark
    this.$vuetify.lang.current = this.appState.lang
    i18n.locale = this.appState.lang
  }
  private toggleDrawer (): void{
    this.setModel(!this.appState.primaryDrawer.model)
    // this.primaryDrawer.model = !this.primaryDrawer.model
  }
}
