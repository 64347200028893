import { api } from '@/api'
import { RootState } from '@/types/Types'
import { TokenState, Token, TokenValue, Pkce } from '@/types/TokenTypes'
import { Module, ActionTree, GetterTree, MutationTree } from 'vuex'
// import { EventBus } from '@/event-bus.ts'

const state: TokenState = {
  token: new Token()
}

// getters
const getters: GetterTree<TokenState, RootState> = {
  getAccessToken: (st: TokenState) => (): string | null => st.token.access_token,
  getRefreshToken: (st: TokenState) => (): string | null => st.token.refresh_token,
  getRoles: (st: TokenState) => (): string[] | undefined => st.token.access_token_decoded?.resource_access.glider.roles,
  isAccessTokenExpired: (st: TokenState) => (): boolean => st.token.access_token_decoded?.exp! < new Date().getTime() / 1000,
  isRefreshTokenExpired: (st: TokenState) => (): boolean => st.token.refresh_token_decoded?.exp! < new Date().getTime() / 1000,
  getState: (st: TokenState) => (): string | null => st.token.session_state,
  hasRole: (st: TokenState) => (roleName: string): string | null => {

    if (st.token.access_token_decoded?.resource_access.glider.roles) {
      const role = st.token.access_token_decoded?.resource_access.glider.roles.find(role => role === roleName)
      return role || null
    }
    return null

  },

  hasAnyOfRoles: (st: TokenState) => (roles: string[]): boolean => {
    let retVal = false
    roles.forEach(role => {
      if (st.token.access_token_decoded?.resource_access.glider.roles.includes(role)) {
        retVal = true
      }
    })
    return retVal
  },

  getAccessTokenDecoded: (st: TokenState) => (): TokenValue | null => st.token.access_token_decoded,
  getPkce: (st: TokenState) => (): Pkce | null => st.token.pkce


}

// actions
const actions: ActionTree<TokenState, RootState> = {
  storeToken({ commit }, token: Object): void {
    commit('STORE_TOKEN', token)
  },
  storePkce({ commit }, pkce: Pkce): void {
    commit('STORE_PKCE', pkce)
  },
  logout({ commit }): void {
    commit('LOGOUT')
  },
  redirectToLogin({ commit, state }): void {
    const redUrl = process.env.VUE_APP_API_AUTHURI + "?" +
      "response_type=code&" +
      "scope=email&" +
      "client_id=" + process.env.VUE_APP_API_CLIENTID + "&" +
      "redirect_uri=" + process.env.VUE_APP_API_REDIRECT_URI + "&" +
      "code_challenge=" + state.token.pkce?.challenge + "&" + 
      "code_challenge_method=S256"

      console.log("REDIRECT URL: " + redUrl)
    // alert("REDIRECTING TO LOGIN")
    window.location.href = redUrl
  },

  // async refreshToken({ commit, state }): Promise<any> {
  //   console.log("refreshing tokens...")

  //   let params = new URLSearchParams();
  //   params.append("grant_type", "refresh_token");
  //   params.append("client_id", process.env.VUE_APP_API_CLIENTID);
  //   params.append("refresh_token", state.token.refresh_token!);
  //   params.append("state", state.token.refresh_token_decoded?.session_state!);


  //   let basic: string = btoa(process.env.VUE_APP_API_CLIENTID + ":" + process.env.VUE_APP_API_CLIENTSECRET)

  //   let config = {
  //     headers: {
  //       "Authorization": "Basic " + basic
  //     }
  //   };
  //   const response = await api.post(process.env.VUE_APP_API_TOKENURI, params, config)
  //   commit("STORE_TOKEN", response.data)


  //   return Promise.resolve(response)
  // },

  retrieveToken({ commit , state}, code: string): Promise<any> {
    // alert("RETRIEVING TOKENS...")
    let params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", process.env.VUE_APP_API_CLIENTID);
    params.append("code_verifier", state.token.pkce?.verifier!)
    // params.append("client_secret", process.env.VUE_APP_API_CLIENTSECRET);
    params.append("redirect_uri", process.env.VUE_APP_API_REDIRECT_URI);
    params.append("code", code);

    let config = {
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
      }
    };
    return api
      .post(process.env.VUE_APP_API_TOKENURI, params, config)
      .then(response => {
        commit("STORE_TOKEN", response.data);
        //this.getRoles().forEach(role => console.log("Role: " + role))
      }).catch(error => {
        console.log("ERROR: ", error)
      });
  }
}

// mutations
const mutations: MutationTree<TokenState> = {
  STORE_PKCE(st: TokenState, pkce: Pkce){
    st.token.pkce = pkce
  },

  STORE_TOKEN(st: TokenState, data: Token) {
    console.log("storing tokens...")
    st.token = data
    let elements: string[] = st.token.access_token!.split('.');
    let tokenValue: TokenValue = JSON.parse(atob(elements[1]))
    st.token.access_token_decoded = tokenValue

    st.token.expires_date = new Date(tokenValue.exp * 1000)

    elements = st.token.refresh_token!.split('.');
    tokenValue = JSON.parse(atob(elements[1]))
    st.token.refresh_token_decoded = tokenValue


    // st.token.accessTokenExpired = false
    // st.token.refreshTokenExpired = false

    // setTimeout(function(){
    //   // EventBus.$emit("AccessTokenExpired")
    //   console.log("ACCESS TOKEN EXPIRED")
    //   st.token.accessTokenExpired = true
    // }, /*(st.token.expires_in) **/ 10000)

    // setTimeout(function(){
    //   // EventBus.$emit("AccessTokenExpired")
    //   st.token.refreshTokenExpired = true
    // }, (st.token.refresh_expires_in) * 1000)

    console.log("at auth time: " + new Date(st.token.access_token_decoded.auth_time * 1000))
    console.log("at iat time: " + new Date(st.token.access_token_decoded.iat * 1000))
    console.log("at exp time: " + new Date(st.token.access_token_decoded.exp * 1000))
    console.log("================================================================================================7")
    console.log("rt auth time: " + new Date(st.token.refresh_token_decoded.auth_time * 1000))
    console.log("rt iat time: " + new Date(st.token.refresh_token_decoded.iat * 1000))
    console.log("rt exp time: " + new Date(st.token.refresh_token_decoded.exp * 1000))

  },
  LOGOUT(st: TokenState) {
    st.token = new Token()
  }
}
export const tokenStore: Module<TokenState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
