































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import { AppState } from '@/types/Types'
import AppNavigation from '@/components/AppNavigation.vue'
import { i18n } from '@/plugins/i18n.ts'

@Component({
  name: 'Settings',
  components: {
    AppNavigation
  }
})
export default class Settings extends Vue {
  @Action('setModel', { namespace: 'appState' }) private setModel: (model: string) => void
  @Action('setData', { namespace: 'appState' }) private setData: (state: AppState) => void
  @Action('setDark', { namespace: 'appState' }) private setDark: (dark: boolean) => void
  @Action('setLang', { namespace: 'appState' }) private setLang: (lang: string) => void
  @State('appState') appState: AppState;

  private drawers: string[]
  private type: string
  private clipped: boolean
  private floating: boolean
  private mini: boolean
  private footerInset: boolean
  private themeDark: boolean

  private lang: string
  private langs: string[] = ['hu', 'en']

private hasRole: boolean;

private created (): void {
  this.drawers = this.appState.drawers

  this.type = this.appState.primaryDrawer.type
  this.clipped = this.appState.primaryDrawer.clipped
  this.mini = this.appState.primaryDrawer.mini
  this.floating = this.appState.primaryDrawer.floating

  this.footerInset = this.appState.footer.inset
  this.themeDark = this.appState.theme.dark

  this.lang = this.appState.lang
}

private refreshStore (): void {
  console.log('Refreshing store...')
  const state = {
    drawers: this.drawers,
    primaryDrawer: {
      model: true,
      type: this.type,
      clipped: this.clipped,
      mini: this.mini,
      floating: this.floating
    },
    footer: {
      inset: this.footerInset
    },
    theme: {
      dark: this.themeDark
    },
    lang: this.lang
  }
  this.setData(state)
}

private setTheme (): void {
  this.$vuetify.theme.dark = this.themeDark
  this.refreshStore()
}

private setLanguage (): void {
  this.$vuetify.lang.current = this.lang
  i18n.locale = this.lang
  this.refreshStore()
}
}
