import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/store';
import vuetify from '@/plugins/vuetify'
import { i18n } from '@/plugins/i18n.ts'

Vue.config.productionTip = false;


new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
