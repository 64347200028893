import { RootState, OrganizationState, User, Organization, OrgUnit, OrganizationUnitMemberShipType } from '@/types/Types'
import { Module, ActionTree, GetterTree, MutationTree } from 'vuex'

const state: OrganizationState = {
  organization: null
}
// getters
const getters: GetterTree<OrganizationState, RootState> = {

  getOrganization: (st: OrganizationState) => (): Organization | null => st.organization,

  getOrgUnits: (st: OrganizationState): OrgUnit[] => st.organization!.orgUnits,

  getMembershipTypes: (st: OrganizationState): OrganizationUnitMemberShipType[] => st.organization!.memberShipTypes

}

// actions
const actions: ActionTree<OrganizationState, RootState> = {
  setCurrentOrganization ({ commit }, org: Organization): void {
    commit('STORE_ORGANIZATION', org)
  },
}
const mutations: MutationTree<OrganizationState> = {
  STORE_ORGANIZATION (st: OrganizationState, data: Organization) {
    st.organization = data
  }
}

// mutations
// const mutations: MutationTree<Organization> = {
//   SET_CURRENT_ORGANIZATION (st: Organization, data: User) {
//     st.or = data
//   }
// }
export const organization: Module<OrganizationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
