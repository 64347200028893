
export type RootState = {}

export class User {
    id: string | null;
    nev: string;
    email: string;
    roles: string[];
    memberShips: OrganizationUnitMemberShip[];
    memberShipTypes: OrganizationUnitMemberShipType[];
    organizations: Organization[];
    befizetesek: Payment[];

    constructor () {
      this.id = null
      this.nev = ''
      this.email = ''
      this.roles = []
      this.memberShips = [new OrganizationUnitMemberShip()]
      this.memberShipTypes = [new OrganizationUnitMemberShipType()]
      this.organizations = []
      this.befizetesek = []
    }
}
export class OrganizationUnitMemberShipType {
    id: string;
    name: string; 
    organization: Organization;
    constructor() {
        this.id = ''
        this.name = ''
        this.organization = new Organization();
    }
}

export class OrganizationUnitMemberShip {
    orgUnit: OrgUnit;
    organizationUnitMemberShipType: OrganizationUnitMemberShipType;
    constructor(){
        this.orgUnit = new OrgUnit()
        this.organizationUnitMemberShipType = new OrganizationUnitMemberShipType()
    }
}

export class Organization {
    id: string;
    name: string;
    orgUnits: OrgUnit[];
    memberShipTypes: OrganizationUnitMemberShipType[];
    paymentTypes: PaymentType[];
    constructor(){
        this.id = ''
        this.name = ''
        this.orgUnits = []
        this.memberShipTypes = []
        this.paymentTypes = []
    }

}

export class OrgUnit {
    id: string;
    name: string;
    organization: Organization;
    constructor(){
        this.id = ''
        this.name = ''
        this.organization = new Organization()
    }
}

export class Payment {
    id: string;
    osszeg: number;
    megjegyzes: string;
    befizetesTipus: PaymentType;
    organization: Organization;
    constructor(){
        this.id = ''
        this.osszeg = 0
        this.megjegyzes = ''
        this.befizetesTipus = new PaymentType()
        this.organization = new Organization()
    }
}

export class PaymentType {
    id: string;
    name: string;
    constructor(){
        this.id = ''
        this.name = ''
    }
}

export class File {
    id: string | null;
    title: string;
    fileName: string;
    fileType: string;
    fileSize: number;
    ownerId: string;
    tmp: boolean;
    largeFileId: string | null;
    file: any | null;
    imgHeight: number;
    imgWidth: number;
    datum: string;

    constructor () {
      this.id = null
      this.title = ''
      this.fileName = ''
      this.fileType = ''
      this.fileSize = 0
      this.ownerId = ''
      this.tmp = true
      this.largeFileId = null
      this.imgHeight = 0
      this.imgWidth = 0
      this.datum = ''
    }
}


export interface AppState {
    drawers: string[];
    primaryDrawer: {
        model: boolean | null;
        type: string;
        clipped: boolean;
        floating: boolean;
        mini: boolean;
    };
    footer: {
        inset: boolean;
    };
    theme: {
        dark: boolean;
    };
    lang: string;
}
export interface UserState {
    user: User | null;
}

export interface OrganizationState {
    organization: Organization | null;
}
