import axios, { AxiosInstance } from 'axios';
import store from '@/store/store'
import { EventBus } from '@/event-bus.ts'

export const api: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
});

api.interceptors.request.use((config) => {
  console.log("returning config")
  return config
}, (error) => {
  return Promise.reject(error)
})
api.interceptors.response.use((response) => {
  return response
}, (error) => {
  console.log("returning error: " + JSON.stringify(error, null, 2))
  EventBus.$emit("errorEvent", error)

  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    console.log("401 HIBA JÖTT MEGPRÓBÁLOM ÚJRAKÜLDENI REFRESH UTÁN")
    let elements: string[] = store.getters["tokenStore/getAccessToken"]().split('.');
    console.log("régi: " + elements[1])
    originalRequest._retry = true;
    let rt: string = store.getters["tokenStore/getRefreshToken"]()
    let state: string = store.getters["tokenStore/getState"]()

    let p: URLSearchParams = new URLSearchParams()
    p.append("grant_type", "refresh_token")
    p.append("client_id", process.env.VUE_APP_API_CLIENTID)
    p.append("refresh_token", rt)
    p.append("state", state)
    let bs: string = btoa(process.env.VUE_APP_API_CLIENTID + ":" + process.env.VUE_APP_API_CLIENTSECRET)

    let conf = {
      headers: {
        "Authorization": "Basic " + bs
      }
    };
    return api.post(process.env.VUE_APP_API_TOKENURI, p, conf).then(response => {
      store.commit("tokenStore/STORE_TOKEN", response.data)
      originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
      console.log("returning originalRequest")
      return api(originalRequest);

    })
  }
  return Promise.reject(error)

})

export default api;
