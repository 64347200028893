


















































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  User,
  Organization,
  OrganizationUnitMemberShipType,
  OrgUnit
} from "@/types/Types";
import PaymentTab from "@/components/PaymentTab.vue";

//import { Item } from "@/types/Dictionary";

import api from "@/api/index";
import { EventBus } from "@/event-bus.ts";

@Component({
  name: "UserForm",
  components: {
    PaymentTab
  }
})
export default class UserForm extends Vue {
  @Getter("getOrganization", { namespace: "organization" })
  private getOrganization: () => Organization;

  private tab = null

  get org(): Organization {
    return this.getOrganization();
  }

  get items(): string[]{
    return this.user.id ? ['Adatok', 'Befizetések', 'Felszállások'] : ['Adatok']
  }

  @Prop({ type: Object, required: true })
  private user: User;

  @Prop({ type: Object, required: true })
  private validation: Object;

  @Watch("user.id")
  userIdChange(): void {
    console.log(JSON.stringify(this.userOrganizationUnitMemberShipType));
    console.log(JSON.stringify(this.userOrgUnit));
  }

  get memberShipTypes(): OrganizationUnitMemberShipType[] | undefined {
    return this.org.memberShipTypes;
  }

  get orgUnits(): OrgUnit[] | undefined {
    return this.org.orgUnits;
  }

  get userOrgUnit(): OrgUnit | null {
    return this.user.memberShips[0].orgUnit;
  }

  set userOrgUnit(orgUnit: OrgUnit | null) {
    console.log(JSON.stringify(orgUnit));
    let newOrgUnit = this.org.orgUnits.find(o => o.id + "" === orgUnit + "");
    if (newOrgUnit) {
      this.user.memberShips[0].orgUnit = newOrgUnit;
    }
    console.log(JSON.stringify(this.user));
  }

  get userOrganizationUnitMemberShipType(): OrganizationUnitMemberShipType {
    return this.user.memberShips[0].organizationUnitMemberShipType;
  }

  set userOrganizationUnitMemberShipType(item: OrganizationUnitMemberShipType) {
    console.log(
      JSON.stringify(this.user.memberShips[0].organizationUnitMemberShipType)
    );
    let newMst = this.org.memberShipTypes.find(e => e.id + "" === item + "");
    if (newMst) {
      this.user.memberShips[0].organizationUnitMemberShipType = newMst;
    }

    console.log(JSON.stringify(this.user));
  }

  private szulDatModal = false;
  private szakszoErvModal = false;
  private orvosiErvenyessegModal = false;

  private onRevalidate(): void {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }
  }

  private required: Function = (v: string): string | boolean =>
    !!v || "Kötelező mező";

  private requiredOrgUnit: Function = (v: OrgUnit): string | boolean =>
    !!v.id || "Kötelező mező";

  private requiredOrgUnitMemberShipType: Function = (v: OrganizationUnitMemberShipType): string | boolean =>
    !!v.id || "Kötelező mező";
}
