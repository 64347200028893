import { RootState, UserState, User, Organization, OrganizationUnitMemberShipType } from '@/types/Types'
import { Module, ActionTree, GetterTree, MutationTree } from 'vuex'

const state: UserState = {
  user: null
}
// getters
const getters: GetterTree<UserState, RootState> = {
  getUser: (st: UserState) => (): User | null => st.user,
  getOrganizations: (st: UserState) => (): Organization[] => st.user!.organizations,
  getMembershipTypes: (st: UserState) => (): OrganizationUnitMemberShipType[] => st.user!.memberShipTypes,
  getUserOrganization: (st: UserState) => (id: string): Organization | undefined => {
    console.log("auth.ts getOrganization: " + id)
    st.user!.organizations.forEach(org => {
      console.log('OrgId: ' + org.id + ', id: ' + id)
      console.log('eval: ' + ((org.id + '') === (id + '')))
    })

    return st.user!.organizations.find(org => ((org.id + '') === (id + '')))

  }

}

// actions
const actions: ActionTree<UserState, RootState> = {
  storeUser ({ commit }, user: User): void {
    commit('STORE_USER', user)
  },
}
const mutations: MutationTree<UserState> = {
  STORE_USER (st: UserState, data: User) {
    st.user = data
  }
}

// mutations
// const mutations: MutationTree<User> = {
//   SET_CURRENT_ORGANIZATION (st: User, data: User) {
//     st.or = data
//   }
// }
export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
