



































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { User, Payment, PaymentType, Organization } from "@/types/Types";
//import { Item } from "@/types/Dictionary";

import api from "@/api/index";
import { EventBus } from "@/event-bus.ts";

@Component({
  name: "PaymentTab",
  components: {}
})
export default class PaymentTab extends Vue {
  @Getter("getOrganization", { namespace: "organization" })
  private getOrganization: () => Organization;

  @Prop({ type: Object, required: true })
  private user: User;

  @Prop({ type: Object, required: true })
  private validation: Object;

  @Watch("user.id")
  private onUserIdChange(): void {
    this.payment = null;
  }

  get org(): Organization {
    return this.getOrganization();
  }
  get paymentType(): PaymentType | undefined {
    if (this.payment) {
      return this.payment.befizetesTipus;
    }
    return undefined;
  }

  set paymentType(item: PaymentType | undefined) {
    let np = this.org.paymentTypes.find(e => e.id + "" === item + "");
    if (np) {
      this.payment!.befizetesTipus = np;
    }

    console.log(JSON.stringify(this.user));
  }

  private payment: Payment | null = null;

  private befizetesTipus: PaymentType | null = null;

  private datumModal = false;

  selectedItems = [];

  headers = [
    {
      text: "Dátum",
      align: "start",
      sortable: true,
      value: "datum"
    },
    {
      text: "Öszeg",
      align: "center",
      sortable: true,
      value: "osszeg"
    },
    {
      text: "Típus",
      align: "center",
      sortable: false,
      value: "befizetesTipus.name"
    },
    {
      text: "Megj",
      align: "left",
      sortable: false,
      value: "megjegyzes"
    }
  ];

  get totalItems(): number {
    if (this.user.befizetesek) {
      return this.user.befizetesek.length;
    }
    return 0;
  }

  private addPayment(): void {
    let payment = new Payment();
    payment.organization = this.org;
    this.user.befizetesek.push(payment);
    this.payment = payment;
  }

  private removePayment(): void {
    const index = this.user.befizetesek.indexOf(this.payment!);
    console.log("removing: " + index);
    if (index > -1) {
      this.user.befizetesek.splice(index, 1);
      this.payment = null;
    }
  }

  private handleRowClick(value: Payment): void {
    console.log("" + JSON.stringify(this.user, null, 2));
    this.payment = value;
  }
  private required: Function = (v: string): string | boolean =>
    !!v || "Kötelező mező";

  private onRevalidate(): void {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }
  }

}
