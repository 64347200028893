



















































































import Vue from "vue";
import AppNavigation from "@/components/AppNavigation.vue";
import UserForm from "@/components/UserForm.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import api from "@/api";
import { EventBus } from "@/event-bus.ts";
import { Item } from "@/types/Dictionary";
import {
  User,
  Organization,
  OrganizationUnitMemberShipType
} from "@/types/Types";

@Component({
  name: "Szemelyek",
  components: {
    AppNavigation,
    UserForm
  }
})
export default class Szemelyek extends Vue {
  @Getter("getOrganization", { namespace: "organization" })
  private getOrganization: () => Organization;

  @Getter("getAccessToken", { namespace: "tokenStore" })
  private getAccessToken: () => string;

  get org(): Organization {
    return this.getOrganization();
  }

  get memberShipTypes(): OrganizationUnitMemberShipType[] | undefined {
    return this.org.memberShipTypes;
  }

  private validation = {
    valid: false,
    paymentsValid: false
  };

  memberShipType: OrganizationUnitMemberShipType | null = null;

  multipleSelectionEnabled: Boolean = false;

  multiSortEnabled: boolean = true;

  searchEnabled: boolean = true;

  id = "szemelyek-list";

  selectedUser: User | null = null;

  headers = [
    {
      text: "Név",
      align: "start",
      sortable: true,
      value: "nev"
    },
    {
      text: "E-mail",
      align: "center",
      sortable: true,
      value: "email"
    },
    {
      text: "Tagviszony",
      align: "center",
      sortable: false,
      value: "tagsagViszony"
    },
    {
      text: "Szül dat.",
      align: "right",
      sortable: false,
      value: "szuletesiDatum"
    }
  ];

  private options = {};

  private items: Node[] = [];

  private totalItems = 0;

  private loading = true;

  private search = "";

  selectedItems = [];
  @Watch("options")
  onOptionsChange(): void {
    this.loadData();
  }

  @Watch("organization")
  onOrganizationChange(): void {
    Vue.set(this.options, "page", 1);
    this.loadData();
  }

  @Watch("memberShipType")
  onMemberShipTypeChange(): void {
    Vue.set(this.options, "page", 1);
    this.loadData();
  }

  @Watch("search")
  private onSearch(): void {
    Vue.set(this.options, "page", 1);
    this.loadData();
  }

  // private mounted(): void {
  //   EventBus.$on("reload-" + this.id, (): void => {
  //     this.loadData();
  //   });
  // }

  private beforeDestroy(): void {
    EventBus.$off("reload-" + this.id);
  }

  private loadData(): void {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken()
      }
    };

    if (this.org) {
      console.log("NODE LIST LOADING DATA");
      this.loading = true;
      Vue.set(this.options, "search", this.search);
      Vue.set(this.options, "organization", this.org.id);
      Vue.set(this.options, "memberShipType", this.memberShipType);
      api
        .post(
          process.env.VUE_APP_API_URL + "/api/private/tag/search",
          this.options,
          config
        )
        .then(response => {
          this.items = response.data.items;
          this.totalItems = response.data.totalCount;
          this.loading = false;
        })
        .catch(error => {
          console.log("error at search: " + error);
        });
    }
  }
  private exportCsv(): void {
    if (this.org) {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getAccessToken()
        }
      };
      Vue.set(this.options, "search", this.search);
      Vue.set(this.options, "organization", this.org.id);
      Vue.set(this.options, "memberShipType", this.memberShipType);
      api
        .post(
          process.env.VUE_APP_API_URL + "/api/private/tag/export",
          this.options,
          config
        )
        .then(res => {
          let blob = new Blob([res.data], { type: "application/*" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "lista.csv";
          link.target = "blank";
          link.click();
        });
    }
  }

  private handleRowClick(value: User): void {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken()
      }
    };
    api
      .get(
        process.env.VUE_APP_API_URL + "/api/private/tag/get/" + value.id,
        config
      )
      .then(response => {
        console.log("" + JSON.stringify(response, null, 2));
        this.selectedUser = response.data;
        this.$emit("rowClick", this.selectedUser);
      })
      .catch(error => {
        console.log("error at search: " + error);
      });
  }

  private selectItems(): void {
    this.$emit("selectItems", this.selectedItems);
  }

  private save(): void {
    console.log(JSON.stringify(this.selectedUser, null, 2));
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAccessToken()
      }
    };
    api
      .post(
        process.env.VUE_APP_API_URL + "/api/private/tag/save",
        this.selectedUser,
        config
      )
      .then(response => {
        console.log("SAVED FILE: " + JSON.stringify(response.data, null, 2));
        this.selectedUser = response.data;
        EventBus.$emit(
          "successEvent",
          this.selectedUser!.nev + " mentése sikeres!"
        );
        this.loadData();
      });
  }

  private deleteUser(): void {
    console.log(JSON.stringify(this.selectedUser, null, 2));
    if (confirm("Biztosan törlöd a tagot?")) {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getAccessToken()
        }
      };
      api
        .post(
          process.env.VUE_APP_API_URL + "/api/private/tag/delete",
          this.selectedUser,
          config
        )
        .then(response => {
          EventBus.$emit(
            "successEvent",
            this.selectedUser!.nev + " törlése sikeres!"
          );
          this.selectedUser = null;
          this.loadData();
          this.validation.valid = false;
        });
    }
  }

  private newUser(): void {
    this.validation.paymentsValid = true;
    this.selectedUser = new User();
  }
}
