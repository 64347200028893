import Vuex, { StoreOptions } from 'vuex'
import Vue from 'vue'
import { appState } from '@/store/modules/appState'
import { organization } from '@/store/modules/organization'
import { user } from '@/store/modules/user'
import { tokenStore } from '@/store/modules/tokenStore'
// import createPersistedState from 'vuex-persistedstate'
import { RootState } from '@/types/Types'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export const state: RootState = {
}

const vuexLocal = new VuexPersistence<RootState>({
  key: 'VUEX_LOCAL',
  storage: window.localStorage,
  modules: ['appState']
  // reducer: (state) => ({ appState: state.appState })
})

const vuexSession = new VuexPersistence<RootState>({
  key: 'VUEX_SESSION',
  storage: window.sessionStorage,
  modules: ['organization', 'tokenStore', 'user']
  // reducer: (state) => ({ auth: state.auth })
})

const plugins = [
  vuexSession.plugin, vuexLocal.plugin
]

const store: StoreOptions<RootState> = {
  plugins,
  state,
  modules: {
    appState,
    organization,
    tokenStore,
    user
  }
}
export default new Vuex.Store<RootState>(store)
