
import api from '@/api/index'
import store from '@/store/store'
import { TokenValue } from '@/types/TokenTypes'


export function findOrCreateUser(): void {

  console.log("FIND OR CREATE USER")
  let token: TokenValue = store.getters["tokenStore/getAccessTokenDecoded"]()
  let params = { ssoId: token.sub, name: token.name, email: token.email };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.getters["tokenStore/getAccessToken"]()
    }
  };
  api
    .post(process.env.VUE_APP_API_URL + "/api/private/tag/find-or-create", params, config)
    .then(response => {
      console.log("find-or-create válaszolt: " + JSON.stringify(response.data, null, 2));
      store.dispatch("user/storeUser", response.data)
    })
    .catch(error => console.log("error: " + error));
}

