import { RootState, AppState } from '@/types/Types'
import { Module, ActionTree, GetterTree, MutationTree } from 'vuex'

const state: AppState = {
  drawers: ['Default (no property)', 'Permanent', 'Temporary'],
  primaryDrawer: {
    model: true,
    type: 'permanent',
    clipped: false,
    floating: false,
    mini: true
  },
  footer: {
    inset: true
  },
  theme: {
    dark: true
  },
  lang: 'hu'
}

// getters
const getters: GetterTree<AppState, RootState> = {
  getDrawers: (st: AppState): string[] => st.drawers,
  getModel: (st: AppState): boolean | null => st.primaryDrawer.model,
  getType: (st: AppState): string | null => st.primaryDrawer.type,
  getLang: (st: AppState): string | null => st.lang,
  getClipped: (st: AppState): boolean => st.primaryDrawer.clipped,
  getFloating: (st: AppState): boolean => st.primaryDrawer.floating,
  getMini: (st: AppState): boolean => st.primaryDrawer.mini,
  getInset: (st: AppState): boolean => st.footer.inset,
  getDark: (st: AppState): boolean => st.theme.dark
}

// actions
const actions: ActionTree<AppState, RootState> = {
  setDark ({ commit }, dark: boolean): void {
    commit('STORE_DARK', dark)
  },
  setLang ({ commit }, lang: boolean): void {
    commit('STORE_LANG', lang)
  },
  setModel ({ commit }, model: boolean): void {
    commit('STORE_MODEL', model)
  },
  setData ({ commit }, state: AppState): void{
    commit('STORE_APPSTATE', state)
  }
}

// mutations
const mutations: MutationTree<AppState> = {
  STORE_DARK (st: AppState, data: boolean) {
    st.theme.dark = data
  },
  STORE_INSET (st: AppState, data: boolean) {
    st.footer.inset = data
  },
  STORE_MINI (st: AppState, data: boolean) {
    st.primaryDrawer.mini = data
  },
  STORE_FLOATING (st: AppState, data: boolean) {
    st.primaryDrawer.floating = data
  },
  STORE_CLIPPED (st: AppState, data: boolean) {
    st.primaryDrawer.clipped = data
  },
  STORE_TYPE (st: AppState, data: string) {
    st.primaryDrawer.type = data
  },
  STORE_MODEL (st: AppState, data: boolean) {
    st.primaryDrawer.model = data
  },
  STORE_LANG (st: AppState, data: string) {
    st.lang = data
  },
  STORE_APPSTATE (st: AppState, data: AppState) {
    // st = data
    st.drawers = data.drawers
    st.footer = data.footer
    st.lang = data.lang
    st.theme.dark = data.theme.dark
    st.primaryDrawer.clipped = data.primaryDrawer.clipped
    st.primaryDrawer.floating = data.primaryDrawer.floating
    st.primaryDrawer.model = data.primaryDrawer.model
    st.primaryDrawer.mini = data.primaryDrawer.mini
    st.primaryDrawer.type = data.primaryDrawer.type
  }
}
export const appState: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
