import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
// import Vuetify from 'vuetify/lib'
import hu from 'vuetify/src/locale/hu'
import en from 'vuetify/src/locale/en'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  // icons: {
  //   iconfont: 'mdi' // default - only for display purposes
  // },
  theme: {
    themes: {
      light: {
        primary: '#D81B60',
        secondary: '#147B0B',
        accent: '#caecd1',
        error: '#b71c1c'
      },
      // dark: {
      //   primary: '#5fc819',
      // },
      dark: {
        primary: '#147B0B',
        secondary: '#147B0B',
        accent: '#394333',
        error: '#ff2d2d'
      }
    }
  },
  lang: {
    locales: { hu, en },
    current: 'hu'
  }
})
